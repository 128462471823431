<template>
  <Dialog
    :visible="modelValue"
    modal
    header="Create custom rule"
    :style="{ width: '75vw' }"
    @update:visible="updateVisibility"
  >
    <div class="pb-3">
      <BidRuleForm
        :editable="true"
        :templates-enabled="true"
        :bid-rule-templates="bidRuleTemplates"
        :campaigns="campaigns"
        @updateForm="updateForm"
      />
      <Toast />
    </div>
    <template #footer>
      <Button
        :loading="loading"
        :disabled="invalidBidRule"
        @click="onCreate"
      >
        Create
      </Button>
    </template>
  </Dialog>
</template>

<script>
import { computed, ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useBidRules } from '@/components/dashboard/bidrules/use-bid-rules';
import { BID_RULE_CREATION_SUCCESS } from '../../../../constants/toastConstants';
import { useValidateForm } from '../../../../utils/formUtils';
import BidRuleForm from '../form/BidRuleForm.vue';
import { a2caAuthorizedClient } from '../../../../api/a2caAuthorizedClient';

import { LAST_SELECTED_RECURRENCY } from '../../../../constants/storageConstants';
import { formBidRuleToApi } from '../../../../utils/bidRuleUtils';

export default {
  components: { BidRuleForm },
  inject: ['query'],
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
    campaigns: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
    bidRuleTemplates: {
      type: Array,
      required: true,
    },
  },
  emits: ['createBidRule', 'update:modelValue'],
  setup(props, { emit }) {
    const currentBidRule = ref({});
    const loading = ref(false);
    const toast = useToast();
    const { isBidRuleValid } = useBidRules();
    const { mutateAsync: createBidRule } = a2caAuthorizedClient.useCreateBidRulesMutation();
    const { validate } = useValidateForm();

    const invalidBidRule = computed(() => !isBidRuleValid(currentBidRule.value));
    const updateVisibility = (newValue) => {
      emit('update:modelValue', newValue);
    };
    const updateForm = (updatedForm) => {
      currentBidRule.value = updatedForm;
    };

    const onCreate = async () => {
      loading.value = true;
      if (!validate(currentBidRule.value)) {
        return;
      }

      const bidRule = formBidRuleToApi(currentBidRule.value);
      const selectedRecurrence = currentBidRule.value.recurrence;

      const createdBidRule = await createBidRule([
        props.selectedProfile.profileId,
        props.selectedProfile.region,
        bidRule,
      ]);

      if (createdBidRule.value?.type) {
        toast.add({
          severity: 'error',
          summary: 'Could not create bid rule',
          detail: 'Make sure all the campaigns in this rule have metrics assigned.',
          life: 3000,
        });
      } else {
        toast.add({
          severity: 'success',
          summary: BID_RULE_CREATION_SUCCESS.summary,
          detail: BID_RULE_CREATION_SUCCESS.detail,
          life: 3000,
        });

        localStorage.setItem(LAST_SELECTED_RECURRENCY, JSON.stringify(selectedRecurrence));
      }
      emit('update:modelValue', false);
      loading.value = false;
    };

    return {
      loading,
      updateVisibility,
      updateForm,
      onCreate,
      invalidBidRule,
    };
  },
};
</script>
