/* eslint-disable no-param-reassign */
import { useToast } from 'primevue/usetoast';
import {
  METRIC_CREATION_METRICS_ERROR,
  METRIC_CREATION_ERROR,
  METRIC_CREATION_NAME_ERROR,
  BID_RULE_CREATION_ERROR,
  BID_RULE_PREDICATE_CREATION_ERROR,
  BID_RULE_RECURRENCE_CREATION_ERROR,
  METRIC_MINMAX_ERROR,
} from '../constants/toastConstants';
import { isDayOfWeekRecurrence } from './bidRuleUtils';

const useValidateForm = () => {
  const toast = useToast();

  const validate = (currentBidRule) => {
    if (
      currentBidRule.campaignTargets.length === 0
      && (!currentBidRule.allCampaignsSelected)
    ) {
      toast.add({
        severity: 'error',
        summary: BID_RULE_CREATION_ERROR.summary,
        detail: BID_RULE_CREATION_ERROR.detail,
        life: 3000,
      });
      return false;
    }

    if (
      currentBidRule.predicates.some(
        (predicate) => predicate.value.value === null,
      )
    ) {
      toast.add({
        severity: 'error',
        summary: BID_RULE_PREDICATE_CREATION_ERROR.summary,
        detail: BID_RULE_PREDICATE_CREATION_ERROR.detail,
        life: 3000,
      });
      return false;
    }

    if (
      isDayOfWeekRecurrence(currentBidRule.recurrence.type.value)
      && currentBidRule.recurrence.dayOfWeek === undefined
    ) {
      toast.add({
        severity: 'error',
        summary: BID_RULE_RECURRENCE_CREATION_ERROR.summary,
        detail: BID_RULE_RECURRENCE_CREATION_ERROR.detail,
        life: 3000,
      });

      return false;
    }

    return true;
  };

  return { validate };
};

const validateMetricForm = (component) => {
  if (
    component.currentMetric.campaigns.length === 0
    && (!component.currentMetric.allCampaignsSelected)
  ) {
    component.$toast.add({
      severity: 'error',
      summary: METRIC_CREATION_ERROR.summary,
      detail: METRIC_CREATION_ERROR.detail,
      life: 3000,
    });
    component.loading = false;
    return false;
  }

  if (
    component.currentMetric.name === null
    || component.currentMetric.name === ''
  ) {
    component.$toast.add({
      severity: 'error',
      summary: METRIC_CREATION_NAME_ERROR.summary,
      detail: METRIC_CREATION_NAME_ERROR.detail,
      life: 3000,
    });
    component.loading = false;
    return false;
  }

  if (
    component.currentMetric.metrics.salesRoyaltiesPercentage === null
    || component.currentMetric.metrics.acorGoal === null
    || component.currentMetric.metrics.minBid === null
    || component.currentMetric.metrics.maxBid === null
  ) {
    component.$toast.add({
      severity: 'error',
      summary: METRIC_CREATION_METRICS_ERROR.summary,
      detail: METRIC_CREATION_METRICS_ERROR.detail,
      life: 3000,
    });
    component.loading = false;
    return false;
  }

  if (
    component.currentMetric.metrics.minBid
    > component.currentMetric.metrics.maxBid
  ) {
    component.$toast.add({
      severity: 'error',
      summary: METRIC_MINMAX_ERROR.summary,
      detail: METRIC_MINMAX_ERROR.detail,
      life: 3000,
    });
    component.loading = false;
    return false;
  }

  return true;
};

export { validateMetricForm, useValidateForm };
