<script setup>
import { useRouter } from 'vue-router';
import {
  computed, ref, defineProps,
} from 'vue';
import { useStore } from 'vuex';
import { getQuickBidRuleSetupSkipped } from '@/constants/storageConstants';
import RuleTemplate from './RuleTemplate.vue';
import { noInterest, clicksNoInterest } from './constants';
import { a2caAuthorizedClient } from '../../api/a2caAuthorizedClient';

const props = defineProps({
  bidRules: {
    type: Array,
  },
});
const router = useRouter();
const { getters } = useStore();
const { selectedProfile } = getters;
const { data: bidRules } = a2caAuthorizedClient.useGetBidRules(selectedProfile.profileId);
const ruleExists = (name) => !!bidRules.value?.bidRules.find((rule) => rule.name === name);

const shouldDialogOpen = ref(
  props.bidRules.length === 0
    && localStorage.getItem(getQuickBidRuleSetupSkipped(selectedProfile.profileId)) !== 'true',
);

const skip = () => {
  localStorage.setItem(getQuickBidRuleSetupSkipped(selectedProfile.profileId), 'true');
  shouldDialogOpen.value = false;
};

const goToMetrics = () => router.push({ name: 'metrics' });

const quickSetUpDone = computed(() => ([noInterest.name, clicksNoInterest.name]).every(
  (quickSetupRule) => !!bidRules.value?.bidRules.find((rule) => rule.name === quickSetupRule),
));

</script>

<template>
  <Dialog
    :visible="shouldDialogOpen"
    modal
    header="Your first bid rules"
    :style="{ width: bidRules && !quickSetUpDone ? '60vw' : '50vw' }"
    :draggable="false"
    @update:visible="shouldDialogOpen = false"
  >
    <section
      v-if="bidRules && !quickSetUpDone"
      class="h-full"
    >
      <div>
        <p>
          Let's start optimising your campaigns by adding your first rules!
        </p>
        <p>
          We recommend setting up your metrics for the best results, but these rules can help you even if you skip that step. With just a click, you can pause targets that aren’t performing well—no detailed setup needed.
        </p>
      </div>

      <article>
        <RuleTemplate
          :rule="noInterest"
          :active="ruleExists(noInterest.name)"
        />
        <RuleTemplate
          :rule="clicksNoInterest"
          :active="ruleExists(clicksNoInterest.name)"
        />
      </article>

      <footer class="flex justify-content-between align-items-center h-4rem">
        <Button
          v-show="!quickSetUpDone"
          class="p-button-secondary"
          @click="skip"
        >
          Skip quick setup
        </Button>
        <Button @click="shouldDialogOpen = false">
          Continue
        </Button>
      </footer>
    </section>
    <section
      v-else
    >
      <p class="text-xl my-3 text-center">
        Great job! Pausing rules are in place. For optimal results, configure your metrics next.
      </p>
      <footer class="flex justify-content-center align-items-center h-4rem">
        <Button
          class="p-button-secondary mr-4 w-8rem text-center"
          style="display: inline-block"
          @click="skip"
        >
          OK
        </Button>
        <Button
          class="w-10rem text-center"
          style="display: inline-block"
          @click="goToMetrics"
        >
          Go to Metrics
        </Button>
      </footer>
    </section>
  </Dialog>
</template>
