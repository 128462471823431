export const noInterest = {
  name: 'No interest at all',
  description: 'This rule is great to pause those targets/keywords that are stealing the visibility from other targets, while not providing you with any royalties.',
  explainedRule: 'This rule will pause the targets in your campaigns that have received more than 3,000 impressions but less than 2 clicks, with no reads or orders.',
};

export const clicksNoInterest = {
  name: 'Clicks, no interest',
  description: 'This rule is great to pause those targets/keywords that are costing you money, but are not giving you any royalties.',
  explainedRule: 'This rule will pause the targets in your campaigns that have reached 20 clicks but did not receive any reads or orders.',
};
