<script setup>
import { defineProps, ref } from 'vue';
import { useStore } from 'vuex';
import { a2caAuthorizedClient } from '@/api/a2caAuthorizedClient';

const props = defineProps({
  rule: {
    type: Object,
    default: () => ({}),
  },
  active: {
    type: Boolean,
  },
});

const { data: bidRuleTemplates } = a2caAuthorizedClient.useGetBidRuleTemplates();
const { mutateAsync: createBidRule } = a2caAuthorizedClient.useCreateBidRulesMutation();

const { getters } = useStore();
const { selectedProfile } = getters;

const isRuleAdded = ref(false);
const isAddingRule = ref(false);

const addRule = async () => {
  isAddingRule.value = true;
  try {
    const templateBidRule = bidRuleTemplates.value.find((bidRule) => bidRule.name === props.rule.name);
    await createBidRule([
      selectedProfile.profileId,
      selectedProfile.region,
      {
        ...templateBidRule,
        allCampaignsSelected: true,
      },
    ]);
    isRuleAdded.value = true;
  } catch (error) {
    console.error(error);
  } finally {
    isAddingRule.value = false;
  }
};
</script>

<template>
  <article class="flex p-3 my-4 template-rule border-primary transition-all transition-duration-500" :class="isRuleAdded || active ? 'opacity-40' : ''">
    <div class="flex flex-1 flex-column">
      <p class="text-lg font-semibold">
        {{ rule.name }} <span
          v-tooltip.top="rule.explainedRule"
          class="pi pi-info-circle mx-1"
        />
      </p>
      <p>
        {{ rule.description }}
      </p>
    </div>
    <Button
      :disabled="isRuleAdded || isAddingRule"
      :loading="isAddingRule"
      @click="addRule"
    >
      {{ isRuleAdded || active ? "Added!" : "Add Rule" }}
    </Button>
  </article>
</template>

<style scoped>
.template-rule {
  width: 100%;
  border: solid 1px black;
  border-radius: 8px;
}
</style>
