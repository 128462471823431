const PREDICATE_OPERANDS = [
  {
    name: 'AND',
    value: 'AND',
  },
  {
    name: 'OR',
    value: 'OR',
  },
];

const METRICS = [
  {
    name: 'Impressions',
    value: 'IMPRESSIONS',
  }, {
    name: 'Clicks',
    value: 'CLICKS',
  }, {
    name: 'Estimated ACOR (%)',
    value: 'TRUE_ACOS',
  }, {
    name: 'ACOS (%)',
    value: 'ACOS',
  }, {
    name: 'Sales',
    value: 'SALES',
  }, {
    name: 'Bids',
    value: 'BIDS',
  }, {
    name: 'Orders',
    value: 'ORDERS',
  }, {
    name: 'CTR (%)',
    value: 'CTR',
  },
  {
    name: 'Spend',
    value: 'SPEND',
  },
  {
    name: 'Cost per Unit ',
    value: 'COST_PER_UNIT',
  },
  {
    name: 'Reads ',
    value: 'READS',
  },
];

const METRIC_OPERANDS = [
  {
    name: '>',
    value: 'GREATER_THAN',
  },
  {
    name: '<',
    value: 'LESS_THAN',
  },
  {
    name: '≥',
    value: 'GREATER_OR_EQUAL_THAN',
  },
  {
    name: '≤',
    value: 'LESS_OR_EQUAL_THAN',
  },
  {
    name: '=',
    value: 'EQUAL',
  },
];

const VALUE_TYPES = [
  {
    name: '$',
    value: 'FIXED',
  },
  {
    name: '%',
    value: 'PERCENTAGE',
  },
];

const RESULT_TYPES = [
  {
    name: 'Increase bid by',
    value: 'INCREASE',
  },
  {
    name: 'Decrease bid by',
    value: 'DECREASE',
  },
  {
    name: 'Pause',
    value: 'PAUSE',
  },
  {
    name: 'Enable',
    value: 'RE_ENABLE',
  },
  {
    name: 'Automate bid optimization',
    value: 'AUTOMATE_BID_OPTIMIZATION',
  },
];

const DAYS_OF_WEEK = [
  {
    name: 'Monday',
    value: 'MONDAY',
  },
  {
    name: 'Tuesday',
    value: 'TUESDAY',
  },
  {
    name: 'Wednesday',
    value: 'WEDNESDAY',
  },
  {
    name: 'Thursday',
    value: 'THURSDAY',
  },
  {
    name: 'Friday',
    value: 'FRIDAY',
  },
  {
    name: 'Saturday',
    value: 'SATURDAY',
  },
  {
    name: 'Sunday',
    value: 'SUNDAY',
  },
];

const RECURRENCE_TYPES = [
  {
    name: 'Daily',
    value: 'DAILY',
  },
  {
    name: 'Weekly',
    value: 'WEEKLY',
  },
  {
    name: 'Bi-weekly',
    value: 'BIWEEKLY',
  },
  {
    name: 'Monthly',
    value: 'MONTHLY',
  },
  {
    name: 'Manual',
    value: 'MANUAL',
  },
];

const EXECUTION_RESULTS = [
  {
    name: 'The target was paused.',
    value: 'TARGET_PAUSED',
  }, {
    name: 'Report data was not present for the target.',
    value: 'NO_DATA',
  },
  {
    name: 'Some of the rule predicates did not match',
    value: 'PREDICATES_NOT_MATCHED',
  },
  {
    name: 'Royalties percentage is not set',
    value: 'NO_ROYALTIES_SET',
  },
  {
    name: 'Revenue multiplier is not set',
    value: 'NO_REVENUE_MULTIPLIER_SET',
  },
];

const METRICS_RELATED_PREDICATES = ['TRUE_ACOS', 'COST_PER_UNIT'];

const findConstantByValue = (constants, value) => constants.find((constant) => constant.value === value);

export {
  PREDICATE_OPERANDS, METRIC_OPERANDS, METRICS, VALUE_TYPES, RESULT_TYPES, DAYS_OF_WEEK, RECURRENCE_TYPES, EXECUTION_RESULTS, METRICS_RELATED_PREDICATES, findConstantByValue,
};
