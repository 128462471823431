import { createRouter, createWebHistory } from 'vue-router';
import BidRulesPage from './components/dashboard/bidrules/BidRulesPage';
import Summary from './components/summary/Summary';
import Home from './components/dashboard/home';
import { TOKEN_KEY, SHOW_PAYMENT_SUCCESS_BANNER, LWA_CANCELLED } from './constants/storageConstants';
import QuickRuleSetup from './components/onboarding/QuickRuleSetup.vue';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('./pages/Login.vue'),
  },
  {
    path: '/password/recover',
    name: 'forgotPassword',
    component: () => import('./pages/ForgotPassword.vue'),
  },
  {
    path: '/password/reset',
    name: 'resetPassword',
    component: () => import('./pages/ResetPassword.vue'),
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('./pages/SignUp.vue'),
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('./pages/Error.vue'),
  },
  {
    path: '/access',
    name: 'access',
    component: () => import('./pages/Access.vue'),
  },
  {
    path: '/lwa/cancelled',
    name: 'lwaCancelled',
    redirect: { name: 'home' },
  },
  {
    path: '/payment/success',
    name: 'paymentSuccess',
    redirect: { name: 'home' },
  },
  {
    path: '/payment/cancelled',
    name: 'paymentCancelled',
    redirect: { name: 'subscribe' },
  },
  {
    path: '/invite',
    name: 'registerWithInvitation',
    redirect: { name: 'signup' },
  },
  {
    path: '/:catchAll(.*)',
    name: 'notfound',
    component: () => import('./pages/NotFound.vue'),
  },
  {
    path: '/',
    name: 'dashboard',
    component: () => import('./pages/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        component: Home,
        name: 'home',
      },
      {
        path: 'summary',
        component: Summary,
        name: 'summary',
      },
      {
        path: 'setup',
        component: QuickRuleSetup,
        name: 'setup',
      },
      {
        path: 'campaigns/:id',
        name: 'campaign',
        component: () => import('./components/dashboard/campaigns/campaign/Campaign.vue'),
        children: [
          {
            path: 'adgroups',
            name: 'adGroups',
            component: () => import('./components/dashboard/campaigns/campaign/CampaignAdGroups.vue'),
          },
        ],
      },
      {
        path: 'campaigns/:campaignId/adgroups/:adGroupId',
        name: 'adGroup',
        component: () => import('./components/dashboard/campaigns/adgroups/AdGroup.vue'),
        children: [
          {
            path: 'targeting',
            name: 'adGroupTargeting',
            component: () => import('./components/dashboard/campaigns/adgroups/AdGroupTargeting.vue'),
          },
          {
            path: 'products',
            name: 'adGroupProducts',
            component: () => import('./components/dashboard/campaigns/adgroups/AdGroupProducts.vue'),
          },
          {
            path: 'searchterms',
            name: 'adGroupSearchTerms',
            component: () => import('./components/dashboard/campaigns/adgroups/AdGroupSearchTerms.vue'),
          },
        ],
      },
      {
        path: 'bidrules',
        name: 'bidRules',
        component: BidRulesPage,
      },
      {
        path: 'bidrules/history',
        name: 'bidrulesHistory',
        component: () => import('./components/dashboard/bidrules/history/keywords/BidRuleHistoryKeywords.vue'),
      },
      {
        path: 'settings/metrics',
        name: 'metrics',
        component: () => import('./components/dashboard/settings/Metrics.vue'),
      },
      {
        path: 'settings/profiles',
        name: 'profiles',
        component: () => import('./components/dashboard/settings/EnabledProfiles.vue'),
      },
      {
        path: 'admin',
        name: 'admin',
        component: () => import('./components/dashboard/admin/AdminDashboard.vue'),
        children: [
          {
            path: '',
            name: 'adminDashoard',
            component: () => import('./components/dashboard/admin/Admin.vue'),
          },
          {
            path: 'invitations',
            name: 'adminInvitations',
            component: () => import('./components/dashboard/admin/AdminInvitations.vue'),
          },
          {
            path: 'discounts',
            name: 'adminDiscounts',
            component: () => import('./components/dashboard/admin/AdminDiscounts.vue'),
          },
        ],
      },
      {
        path: 'admin/:accountId/productAds',
        name: 'adminProductAds',
        component: () => import('./components/dashboard/admin/AdminProductAds.vue'),
      },
      {
        path: 'account',
        name: 'myAccount',
        component: () => import('./components/dashboard/account/MyAccount.vue'),
        children: [
          {
            path: 'info',
            name: 'myAccountInfo',
            component: () => import('./components/dashboard/account/MyAccountInfo.vue'),
          },
        ],
      },
      {
        path: 'subscribe',
        name: 'subscribe',
        component: () => import('./components/dashboard/subscribe/Subscribe.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// auth guard
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem(TOKEN_KEY) === null) {
      next({
        name: 'login',
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

// handle login/signup
router.beforeEach((to, from, next) => {
  if (to?.redirectedFrom?.name === 'paymentCancelled') {
    localStorage.setItem(SHOW_PAYMENT_SUCCESS_BANNER, JSON.stringify(false));
  }

  if (to?.redirectedFrom?.name === 'lwaCancelled') {
    console.debug(to.redirectedFrom.query);
    localStorage.setItem(LWA_CANCELLED, to.redirectedFrom.query.duplicate);
  }

  if (
    to.matched.some(
      (record) => record.name === 'login' || record.name === 'signup',
    )
  ) {
    // Set the token value when it's passed by query; used when logging in to test environment
    const queryTokenValue = to.query?.access_token ?? null;
    if (queryTokenValue !== null) {
      localStorage.setItem(TOKEN_KEY, queryTokenValue);
    }
    if (localStorage.getItem(TOKEN_KEY) !== null) {
      next({
        name: 'home',
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
