<template>
  <Message
    v-if="setupState && !setupState.hasSetupMetrics"
    severity="warn"
    :closable="false"
  >
    Seems like you haven't set up any metric group yet. This will limit the bid rules that you can create.
    <RouterLink
      class="underline text-yellow-700 font-bold"
      :to="{ name: 'metrics' }"
    >
      Set up metrics
    </RouterLink>
  </Message>
  <BidRuleTemplates
    v-model="createTemplateDialogVisible"
    :campaigns="campaigns"
    :templates="bidRuleTemplates"
    :selected-profile="selectedProfile"
    :bid-rules="bidRules"
  />
  <BidRuleCreation
    v-model="createRuleDialogVisible"
    :selected-profile="selectedProfile"
    :campaigns="campaigns"
    :bid-rule-templates="bidRuleTemplates"
  />
  <section v-if="hasBidRules">
    <div class="pb-5 pt-2 text-left flex">
      <div v-tooltip="{ value: 'All quick rules have been added.', disabled: !allBidRulesExist }">
        <Button
          :loading="false"
          class="p-button-primary mr-3"
          :disabled="allBidRulesExist"
          @click="createTemplateDialogVisible = true"
        >
          Add Quick Rules
        </Button>
      </div>
      <Button
        :loading="false"
        class="p-button-primary mr-3"
        @click="createRuleDialogVisible = true"
      >
        Add Custom Rule
      </Button>
      <Button
        v-show="hasManualBidRules"
        :loading="executingBidRules"
        class="p-button-secondary"
        @click="executeBidRules"
      >
        Execute Manual Rules
      </Button>
    </div>

    <BidRuleListing
      :selected-profile="selectedProfile"
      :campaigns="campaigns"
      :bid-rules="bidRules"
    />
  </section>
  <Card v-else>
    <template #title>
      Bid Rules
    </template>
    <template #content>
      <section>
        <p>It seems like you haven't set up any bid rule yet. </p>

        <Button
          v-tooltip="{ value: 'All quick rules have been added.', disabled: !allBidRulesExist }"
          :loading="false"
          class="p-button-primary mr-3"
          :disabled="allBidRulesExist"
          @click="createTemplateDialogVisible = true"
        >
          Add Quick Rules
        </Button>
        <Button
          :loading="false"
          class="p-button-primary mr-3"
          @click="createRuleDialogVisible = true"
        >
          Add Custom Rule
        </Button>
      </section>
    </template>
  </Card>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useStore } from 'vuex';
import BidRuleCreation from './BidRuleCreation.vue';
import BidRuleTemplates from './BidRuleTemplates.vue';
import BidRuleListing from './BidRuleListing.vue';
import { a2caAuthorizedClient } from '../../../../api/a2caAuthorizedClient';

export default {
  components: { BidRuleCreation, BidRuleListing, BidRuleTemplates },
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
    campaigns: {
      type: Array,
      required: true,
    },
    bidRules: {
      type: Array,
      required: true,
    },
    bidRuleTemplates: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const executingBidRules = ref(false);
    const createRuleDialogVisible = ref(false);
    const createTemplateDialogVisible = ref(false);
    const toast = useToast();
    const { getters } = useStore();
    const { selectedProfile } = getters;
    const { data: setupState } = a2caAuthorizedClient.useGetSetUpState(selectedProfile.profileId);

    const hasBidRules = computed(() => props.bidRules.length > 0);

    const hasManualBidRules = computed(() => props.bidRules.some((bidRule) => bidRule.recurrence.type === 'MANUAL'));

    const bidRuleTemplateExists = (template) => props.bidRules.find((br) => br.name === template.name);

    const allBidRulesExist = ref(props.bidRuleTemplates.every(bidRuleTemplateExists));
    watch(() => [props.bidRuleTemplates, props.bidRules], ([newTemplates]) => {
      allBidRulesExist.value = newTemplates.every(bidRuleTemplateExists);
    });

    const executeBidRules = async () => {
      executingBidRules.value = true;
      const executionResult = await a2caAuthorizedClient.executeBidRules(selectedProfile.profileId, selectedProfile.region);
      if (executionResult === 'OK') {
        toast.add({
          severity: 'success',
          summary: 'Running bid rules',
          detail: 'Bid rule execution started',
          life: 1500,
        });
      } else if (executionResult === 'NO_SALES_ROYALTIES') {
        toast.add({
          severity: 'error',
          summary: 'No bid rules run',
          detail: 'Net Royalty Rate is not set.',
          life: 1500,
        });
      } else if (executionResult === 'NO_PROFILE') {
        toast.add({
          severity: 'error',
          summary: 'No bid rules run',
          detail: 'Could not find current profile information.',
          life: 1500,
        });
      } else if (executionResult === 'NO_MATCHING_ENVIRONMENT') {
        toast.add({
          severity: 'error',
          summary: 'No bid rules run',
          detail: 'Account is not allowed to run rules in the current environment.',
          life: 1500,
        });
      } else if (executionResult === 'MAX_AD_SPEND') {
        toast.add({
          severity: 'error',
          summary: 'Max ad spend reached',
          detail: 'You have reached the max ad spend for your current plan; cannot execute bid rules.',
          life: 3000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: 'No bid rules run',
          detail: 'There are no bid rules created.',
          life: 1500,
        });
      }
      executingBidRules.value = false;
    };

    return {
      hasBidRules,
      setupState,
      executeBidRules,
      executingBidRules,
      createRuleDialogVisible,
      createTemplateDialogVisible,
      allBidRulesExist,
      hasManualBidRules,
    };
  },
};
</script>
