<template>
  <div
    v-if="loading"
    class="text-center"
  >
    <ProgressSpinner />
  </div>
  <template v-else>
    <div
      class="
      pb-5"
    >
      <Card>
        <template #content>
          <div class="text-right mb-3">
            <Button
              v-if="accountType === 'ADMIN'"
              type="button"
              class="mr-2"
              label="Download reports"
              :loading="downloadingReports"
              @click="downloadReports"
            />
            <ReportsCalendar
              v-model="dateRange"
              report-type="CAMPAIGNS_AND_ADGROUPS"
            />
          </div>
          <GeneralChart
            :reports="generalChartReports"
            :kpi-aggregation="kpiAggregation"
          />
        </template>
      </Card>
    </div>
    <div
      v-if="campaigns.loading"
      class="text-center"
    >
      <ProgressSpinner />
    </div>
    <template v-else>
      <div class="pb-5">
        <Card>
          <template
            #content
          >
            <CampaignChart
              :campaigns="campaigns.data"
              :date-range="dateRange"
            />
          </template>
        </Card>
      </div>
      <Card>
        <template #content>
          <Campaigns
            :reports="reports"
            :selected-profile="selectedProfile"
            :api-campaigns="campaigns.data"
            :date-range="dateRange"
            :total="kpiAggregation"
          />
        </template>
      </Card>
    </template>
  </template>
  <Toast />
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import A2CAClient from '../../../api/a2caAuthorizedClient';
import {
  ReportType, ReportStatus, REPORT_DATE_FORMAT, ApiReportType,
} from '../../../constants/reportConstants';

import GeneralChart from './GeneralChart.vue';
import CampaignChart from './CampaignChart.vue';
import Campaigns from '../campaigns/Campaigns.vue';
import ReportsCalendar from '../../ReportsCalendar.vue';
import { getDateRange } from '../../../utils/dateUtils';

let reportFetchInterval = null;

export default {
  components: {
    GeneralChart, CampaignChart, Campaigns, ReportsCalendar,
  },
  inject: ['query'],
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
  },
  emits: ['clear-selected-profile', 'change-menu-layout'],
  data() {
    return {
      loading: false,
      downloadingReports: false,
      reports: null,
      dateRange: getDateRange(!!this.query?.mockAccountId),
      aggregatedReports: null,
      generalChartReports: null,
      kpiAggregation: null,
      campaigns: {
        loading: true,
        data: null,
      },
    };
  },
  computed: {
    ...mapGetters(['royalties', 'selectedCampaigns', 'accountType']),
  },
  watch: {
    dateRange(range) {
      if (range[1] !== null) {
        this.retrieveReports();
      }
    },
    selectedProfile: {
      handler() {
        // If selected profile changes while changes are being fetched, clear the current fetching interval
        if (this.loading) {
          clearInterval(reportFetchInterval);
        }
        this.fetchCampaignsAndReports();
      },
      immediate: true,
    },
    reports(val) {
      // When reports are set to a non-null value, the report fetch interval should be stopped
      if (val !== null) {
        console.debug('Reports have changed. Clearing fetching interval...');
        clearInterval(reportFetchInterval);
        reportFetchInterval = null;
      }
    },
  },
  methods: {
    retrieveReports() {
      this.loading = true;
      this.reports = null;
      this.fetchReports();
    },
    async fetchReports() {
      console.debug('Fetching reports...');
      const client = new A2CAClient(this.query);
      const reportsResponse = await client.getReports(
        this.selectedProfile.profileId,
        this.selectedProfile.region,
        ReportType.CAMPAIGNS,
        dayjs(this.dateRange[0]).format(REPORT_DATE_FORMAT), dayjs(this.dateRange[1]).format(REPORT_DATE_FORMAT),
      );
      const generalChartResponse = await client.getGeneralChartData(
        this.selectedProfile.profileId,
        this.selectedProfile.region,
        ReportType.CAMPAIGNS,
        dayjs(this.dateRange[0]).format(REPORT_DATE_FORMAT), dayjs(this.dateRange[1]).format(REPORT_DATE_FORMAT),
      );
      this.processReports(reportsResponse, generalChartResponse);
    },
    async fetchCampaignsAndReports() {
      // Fetch campaigns
      this.loading = true;
      this.reports = null;
      this.campaigns.loading = true;

      const client = new A2CAClient(this.query);
      const campaignRequest = client.getCampaignsWithState(
        this.selectedProfile.profileId,
        this.selectedProfile.region,
        'ENABLED',
      );
      const reportRequest = client.getReports(
        this.selectedProfile.profileId,
        this.selectedProfile.region,
        ReportType.CAMPAIGNS,
        dayjs(this.dateRange[0]).format(REPORT_DATE_FORMAT), dayjs(this.dateRange[1]).format(REPORT_DATE_FORMAT),
      );

      const generalChartRequest = client.getGeneralChartData(
        this.selectedProfile.profileId,
        this.selectedProfile.region,
        ReportType.CAMPAIGNS,
        dayjs(this.dateRange[0]).format(REPORT_DATE_FORMAT), dayjs(this.dateRange[1]).format(REPORT_DATE_FORMAT),
      );

      const [campaigns, reports, generalChartResponse] = await Promise.all([campaignRequest, reportRequest, generalChartRequest]);

      this.campaigns.data = campaigns;
      this.campaigns.loading = false;
      this.processReports(reports, generalChartResponse);
    },
    processReports(reportsResponse, generalChartResponse) {
      const { reportData } = reportsResponse.parsedReport;
      this.reports = reportData;

      this.aggregatedReports = reportData;
      this.generalChartReports = generalChartResponse.dailyAggregations;
      this.kpiAggregation = reportsResponse.aggregation;
      this.loading = false;
    },
    async downloadReports() {
      console.debug('Downloading reports...');
      this.downloadingReports = true;
      const client = new A2CAClient(this.query);
      const responses = [];
      Object.values(ApiReportType).forEach(type => {
        const response = client.downloadReports(
          this.selectedProfile.profileId,
          this.selectedProfile.region,
          type,
          dayjs(this.dateRange[0]).format(REPORT_DATE_FORMAT), dayjs(this.dateRange[1]).format(REPORT_DATE_FORMAT),
        );
        responses.push(response);
      });

      const reportsResponse = (await Promise.all(responses)).flat().flatMap((downloadResponse) => downloadResponse.requestedReports);

      if (
        !reportsResponse.every(
          (report) => report.state === ReportStatus.COMPLETE,
        )
      ) {
        this.$toast.add({
          severity: 'info',
          summary: 'Data download in progress',
          detail: 'Data download has started and is in progress',
          life: 5000,
        });
      } else {
        this.$toast.add({
          severity: 'info',
          summary: 'Data already downloaded',
          detail: 'The requested data has already been downloaded',
          life: 5000,
        });
      }
      this.downloadingReports = false;
    },
  },
};
</script>
