<template>
  <Card>
    <template #title>
      <h2 class="text-2xl">
        Bidding Rules List
      </h2>
    </template>
    <template #content>
      <TabView
        v-model:activeIndex="active"
      >
        <TabPanel :header="`Increase (${increaseBidRules.length})`">
          <BidRuleListingTab
            :selected-profile="selectedProfile"
            :campaigns="campaigns"
            :bid-rules="increaseBidRules"
            @deleteBidRule="deleteBidRule"
          />
        </TabPanel>
        <TabPanel :header="`Decrease (${decreaseBidRules.length})`">
          <BidRuleListingTab
            :selected-profile="selectedProfile"
            :campaigns="campaigns"
            :bid-rules="decreaseBidRules"
            @deleteBidRule="deleteBidRule"
          />
        </TabPanel>
        <TabPanel :header="`Pause/Enable (${pauseAndEnableBidRules.length})`">
          <BidRuleListingTab
            :selected-profile="selectedProfile"
            :campaigns="campaigns"
            :bid-rules="pauseAndEnableBidRules"
            @deleteBidRule="deleteBidRule"
          />
        </TabPanel>
        <TabPanel :header="`Automate (${automateBidRules.length})`">
          <BidRuleListingTab
            :selected-profile="selectedProfile"
            :campaigns="campaigns"
            :bid-rules="automateBidRules"
            @deleteBidRule="deleteBidRule"
          />
        </TabPanel>
      </TabView>
    </template>
  </Card>
  <Toast />
</template>
<script>
import BidRuleListingTab from './BidRuleListingTab.vue';

export default {
  components: { BidRuleListingTab },
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
    campaigns: {
      type: Array,
      required: true,
    },
    bidRules: {
      type: Array,
      required: true,
    },

  },
  emits: ['deleteBidRule'],
  data() {
    return {
      active: 0,
    };
  },
  computed: {
    increaseBidRules() {
      return this.bidRules.filter((rule) => rule.result.type === 'INCREASE');
    },
    decreaseBidRules() {
      return this.bidRules.filter((rule) => rule.result.type === 'DECREASE');
    },
    pauseAndEnableBidRules() {
      return this.bidRules.filter((rule) => [
        'PAUSE',
        'RE_ENABLE',
      ].includes(rule.result.type));
    },
    automateBidRules() {
      return this.bidRules.filter((rule) => rule.result.type === 'AUTOMATE_BID_OPTIMIZATION');
    },
  },
  mounted() {
    const firstBidRule = this.bidRules.at(0);

    if (firstBidRule) {
      this.active = this.getFirstBidRuleIndex(firstBidRule.result.type);
    }
  },
  methods: {
    deleteBidRule(bidRuleId) {
      this.$emit('deleteBidRule', bidRuleId);
    },
    getFirstBidRuleIndex(type) {
      switch (type) {
        case 'INCREASE':
          return 0;
        case 'DECREASE':
          return 1;
        case 'PAUSE':
        case 'RE_ENABLE':
          return 2;
        case 'AUTOMATE_BID_OPTIMIZATION':
          return 3;
        default:
          return 0;
      }
    },
  },
};
</script>
