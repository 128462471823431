<template>
  <span hidden="hidden">
    {{ key }}
  </span>
  <div
    v-if="isLoading"
    class="text-center"
  >
    <ProgressSpinner />
  </div>
  <template v-else-if="campaigns && bidRules && bidRuleTemplates">
    <BidRules
      :selected-profile="selectedProfile"
      :campaigns="campaigns"
      :bid-rules="bidRules.bidRules"
      :bid-rule-templates="bidRuleTemplates"
    />
    <QuickRuleSetup
      v-if="!isDialogSkipped && !setupState.hasSetupMetrics"
      :bid-rules="bidRules.bidRules"
    />
  </template>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { getQuickBidRuleSetupSkipped } from '@/constants/storageConstants';
import BidRules from './list/BidRules.vue';
import { a2caAuthorizedClient } from '../../../api/a2caAuthorizedClient';
import QuickRuleSetup from '../../onboarding/QuickRuleSetup.vue';

export default {
  components: { QuickRuleSetup, BidRules },
  props: {
    key: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['clear-selected-profile', 'change-menu-layout'],
  setup() {
    const { getters } = useStore();
    const { selectedProfile } = getters;

    const { data: setupState } = a2caAuthorizedClient.useGetSetUpState(selectedProfile.profileId);
    const isDialogSkipped = ref(localStorage.getItem(getQuickBidRuleSetupSkipped(selectedProfile.profileId)) === 'true');
    const { data: campaigns, isFetching: isCampaignsLoading } = a2caAuthorizedClient.useGetCampaignsWithMetrics(
      selectedProfile.profileId,
      selectedProfile.region,
      'ENABLED',
    );
    const { data: bidRules, isFetching: isBidRulesLoading } = a2caAuthorizedClient.useGetBidRules(selectedProfile.profileId);
    const { data: bidRuleTemplates, isFetching: isTemplatesLoading } = a2caAuthorizedClient.useGetBidRuleTemplates();

    const isLoading = computed(() => isCampaignsLoading.value
        && isBidRulesLoading.value
        && isTemplatesLoading.value);

    return {
      setupState,
      isDialogSkipped,
      selectedProfile,
      bidRules,
      bidRuleTemplates,
      campaigns,
      isLoading,
    };
  },
};
</script>
