<script setup>
import { defineProps, defineEmits } from 'vue';

const TYPE_ICON_MAP = {
  RE_ENABLE: 'play',
  PAUSE: 'stop-circle',
  INCREASE: 'angle-double-up',
  DECREASE: 'angle-double-down',
  AUTOMATE_BID_OPTIMIZATION: 'prime',
};

const emit = defineEmits(['click']);

const props = defineProps({
  isExtend: { type: Boolean, default: false },
  selected: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  template: { type: Object, required: true },
  disabledMessage: { type: String, default: 'This quick bid rule has already been added.' },
});

const onClick = () => {
  if (props.disabled) return;
  emit('click', props.template);
};
</script>

<template>
  <article
    v-tooltip.top="{ value: disabledMessage, disabled: !disabled }"
    class="bid-rule-checkbox__wrapper flex align-items-center p-3 border-round-md border-solid border-300"
    :class="{
      'border-primary text-primary': selected,
      'border-300 text-300': disabled,
    }"
    :style="{ cursor: disabled ? 'not-allowed' : 'pointer' }"
    @click="onClick"
  >
    <i
      :class="`pi pi-${TYPE_ICON_MAP[template.result.type]} mr-3`"
      style="font-size: 2rem"
    />
    <div>
      <h3 class="text-base mb-1 font-bold">
        {{ template.name }}
      </h3>
      <p class="text-sm">
        {{ template.description }}
      </p>
      <p
        v-if="isExtend"
        class="text-xs text-primary"
      >
        The existing rule will be extended with new campaigns.
      </p>
    </div>
  </article>
</template>

<style scoped lang="scss">
.bid-rule-checkbox {
  &__wrapper {
    display: flex;
    border-radius: 8px;
  }
}
</style>
