import axios from 'axios';
import { TOKEN_KEY } from '../constants/storageConstants';

const getToken = () => {
  const token = window.localStorage.getItem(TOKEN_KEY);
  if (!token) return null;
  return `Bearer ${token}`;
};

const getAuthorizedConfig = (mockId) => {
  const token = getToken();

  return token ? axios.create({
    baseURL: '/api/private',
    headers: { Authorization: token, ...(mockId ? { 'Mock-Account-Id': mockId } : {}) },
  })
    : axios.create({
      baseURL: '/api/public',
    });
};

const getConfig = () => axios.create({
  baseURL: '/api/public',
});

export { getAuthorizedConfig, getConfig };
