export const TOKEN_KEY = 'accessToken';
export const SELECTED_CAMPAIGNS_KEY = 'selectedCampaigns';
export const SELECTED_DATE_RANGE_KEY = 'selectedDateRange';
export const LAST_SELECTED_RECURRENCY = 'lastSelectedRecurrency';
export const SELECTED_PROFILE_KEY = 'selectedProfile';
export const SHOW_PAYMENT_SUCCESS_BANNER = 'showPaymentSuccessBanner';
export const LWA_CANCELLED = 'lwaCancelled';
export const BID_RULE_SETUP_SKIPPED = 'bidRuleSetupSkipped';

export const getProfileSelectedCampaignsKey = (profileId) => `${SELECTED_CAMPAIGNS_KEY}_${profileId}`;
export const getQuickBidRuleSetupSkipped = (profileId) => `${BID_RULE_SETUP_SKIPPED}_${profileId}`;
