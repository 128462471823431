<template>
  <Dialog
    v-model:visible="showCampaignMultiselect"
    modal
    header="Campaigns"
    :style="{ width: '50vw' }"
    :closable="false"
  >
    <div class="flex flex-wrap pb-5">
      <div class="target-dropdown">
        <label
          for="campaignTargets"
          class="block pb-2 text-base font-semibold"
        >
          Campaigns
          <span
            v-show="!selectedCampaignsHaveMetrics"
            v-tooltip="'Some campaigns do not have metrics assigned.'"
            class="pi pi-exclamation-triangle text-orange-500"
          />
        </label>
        <MultiSelect
          id="campaignTargets"
          v-model="value.campaignTargets"
          class="full-dropdown"
          :options="campaigns"
          option-label="name"

          placeholder="Select one or more campaigns"
          :filter="true"
          display="chip"
          :disabled="value.allCampaignsSelected"
          :show-toggle-all="false"
        />
      </div>
    </div>
    <div class="flex">
      <div class="flex justify-content-end w-full">
        <div class="flex align-items-center justify-content-end w-full">
          <Checkbox
            v-model="value.allCampaignsSelected"
            input-id="selectAllCampaignsCheckbox"
            :binary="true"
          />
          <label
            for="selectAllCampaignsCheckbox"
            class="ml-2 mr-3"
          > Select all campaigns </label>
        </div>
        <Button
          size="small"
          @click="showCampaignMultiselect = false"
        >
          OK
        </Button>
      </div>
    </div>
  </Dialog>

  <div class="flex flex-wrap pb-5">
    <div class="mr-4">
      <label
        for="campaignName"
        class="block pb-2 text-base font-semibold"
      >
        Rule Name <span class="font-italic font-light">(optional)</span>
      </label>
      <InputText
        id="campaignName"
        v-model="value.name"
        class="mr-1"
        :disabled="!editable"
      />
    </div>
    <div
      class="target-dropdown flex align-items-end"
    >
      <Button
        class="p-button-secondary h-3rem mr-3 text-left flex-shrink-0"
        label="Select campaigns"
        icon="pi pi-external-link"
        :disabled="!editable"
        @click="showCampaignMultiselect = true"
      />
      <InlineMessage
        v-if="!hasSelectedCampaign"
        severity="warn"
      >
        Select the campaigns you want this bid rule to apply to.
      </InlineMessage>
      <InlineMessage
        v-if="invalidPredicate"
        severity="error"
      >
        Some campaigns do not have metrics assigned. This rule will not apply to them.
      </InlineMessage>
      <InlineMessage
        v-else-if="!selectedCampaignsHaveMetrics && editable"
        severity="warn"
      >
        Some campaigns do not have metrics assigned. This may limit the available configurations.
      </InlineMessage>
    </div>
  </div>
  <div v-if="templatesEnabled">
    <label
      for="bidRuleTemplates"
      class="block pb-3 text-base font-semibold"
    >
      Rule Templates
    </label>
    <Dropdown
      id="bidRuleTemplates"
      v-model="currentBidRuleTemplate"
      class="fixed-dropdown mb-3"
      :options="filteredBidRuleTemplates"
      option-label="name"
      placeholder="Choose a template"
      :disabled="!hasSelectedCampaign"
    />
  </div>
  <div class="flex pb-5">
    <div class="if-label font-semibold text-xl">
      <p>IF</p>
    </div>
    <div class="flex flex-row align-items-end button-gap">
      <div>
        <div
          v-for="(predicate, index) in value.predicates"
          :key="value.predicates[index].id"
          class="flex flex-row button-gap pt-3"
        >
          <BidRulePredicate
            v-model="value.predicates[index]"
            :editable="editable && hasSelectedCampaign"
            :has-metrics="selectedCampaignsHaveMetrics"
          />
          <Button
            v-if="index !== 0"
            v-show="editable && hasSelectedCampaign"
            class="p-button-danger"
            @click="() => removePredicate(value.predicates[index].id)"
          >
            Remove
          </Button>
        </div>
      </div>
      <Button
        v-show="editable && hasSelectedCampaign"
        class="p-button-secondary"
        @click="addPredicate"
      >
        Add
      </Button>
    </div>
    <div class="flex flex-1 justify-content-end">
      <div>
        <label
          for="campaignTargets"
          class="block pb-2 text-base font-semibold"
        >
          Rule Operator
        </label>
        <Dropdown
          v-model="value.predicateOperand"
          class="small-dropdown"
          :options="predicateOperands"
          option-label="name"
          placeholder="Select an operand"
          :disabled="!editable || !hasSelectedCampaign"
        />
      </div>
    </div>
  </div>
  <div class="flex">
    <div class="if-label font-semibold text-xl">
      <p>THEN</p>
    </div>
    <div class="flex predicate-gap">
      <Dropdown
        v-model="value.result.type"
        :options="resultTypes"
        option-label="name"
        :option-disabled="filterResult"
        placeholder="Select a result type"
        class="metric-dropdown"
        :disabled="disableResultType || !hasSelectedCampaign"
      />

      <BidRuleValue
        v-if="value.result.type.value === 'INCREASE' || value.result.type.value === 'DECREASE'"
        v-model="value.result.value"
        :editable="editable && hasSelectedCampaign"
        :edit-id="value.editId"
        :cancelled="value.campaignTargets.cancelled"
      />
    </div>
  </div>
  <div class="flex justify-content-end">
    <div class="flex-column flex">
      <label
        for="campaignTargets"
        class="block pb-2 text-base font-semibold"
      >
        Frequency
      </label>
      <div>
        <Dropdown
          v-model="value.recurrence.type"
          :options="recurrenceTypes"
          option-label="name"
          placeholder="Select a recurrence type"
          :disabled="!editable"
        />

        <Dropdown
          v-if="hasWeekdays(value.recurrence.type.value)"
          v-model="value.recurrence.dayOfWeek"
          :options="weekdays"
          option-label="name"
          placeholder="Select a day"
          :disabled="!editable"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { LAST_SELECTED_RECURRENCY } from '@/constants/storageConstants';
import BidRulePredicate from './BidRulePredicate.vue';
import BidRuleValue from './BidRuleValue.vue';
import {
  PREDICATE_OPERANDS,
  RESULT_TYPES,
  DAYS_OF_WEEK,
  RECURRENCE_TYPES,
  METRIC_OPERANDS,
  METRICS,
  VALUE_TYPES,
  METRICS_RELATED_PREDICATES,
} from '../../../../constants/bidRulesConstants';
import { isDayOfWeekRecurrence, apiBidRuleToForm } from '../../../../utils/bidRuleUtils';

const BID_RULE_TEMPLATE = {
  name: '',
  campaignTargets: [],
  predicateOperand: PREDICATE_OPERANDS[0],
  predicates: [
    {
      operand: METRIC_OPERANDS[0],
      metric: METRICS[0],
      value: 0,
    },
  ],
  result: {
    // eslint-disable-next-line vue/no-unused-properties
    type: RESULT_TYPES[0],
    // eslint-disable-next-line vue/no-unused-properties
    value: {
      value: 0,
      type: VALUE_TYPES[0],
    },
  },
  recurrence: JSON.parse(localStorage.getItem(LAST_SELECTED_RECURRENCY)) ?? {
    // eslint-disable-next-line vue/no-unused-properties
    type: RECURRENCE_TYPES[0],
    // eslint-disable-next-line vue/no-unused-properties
    dayOfWeek: DAYS_OF_WEEK[0],
  },
  allCampaignsSelected: false,
};

export default {
  components: { BidRulePredicate, BidRuleValue },
  props: {
    initialValue: {
      type: Object,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    templatesEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableResultType: {
      type: Boolean,
      required: false,
      default: false,
    },
    campaigns: {
      type: Array,
      required: true,
    },
    bidRuleTemplates: {
      type: Array,
      required: true,
    },
  },
  emits: ['updateForm'],
  data() {
    return {
      value: JSON.parse(JSON.stringify(this.initialValue || BID_RULE_TEMPLATE)),
      predicateOperands: PREDICATE_OPERANDS,
      resultTypes: RESULT_TYPES,
      recurrenceTypes: RECURRENCE_TYPES,
      weekdays: DAYS_OF_WEEK,
      showCampaignMultiselect: false,
      currentBidRuleTemplate: null,
    };
  },
  computed: {
    selectedCampaignsHaveMetrics() {
      if (this.value.allCampaignsSelected) {
        return this.campaigns.every((c) => c.hasMetrics);
      }
      return this.value.campaignTargets.every((c) => c.hasMetrics);
    },
    hasSelectedCampaign() {
      return this.value.campaignTargets.length > 0 || this.value.allCampaignsSelected;
    },
    filteredBidRuleTemplates() {
      if (this.selectedCampaignsHaveMetrics) {
        return this.bidRuleTemplates;
      }
      return this.bidRuleTemplates
        .filter((brTemplate) => brTemplate.result.type !== 'AUTOMATE_BID_OPTIMIZATION')
        .filter((brTemplate) => !brTemplate.predicates.some((predicate) => METRICS_RELATED_PREDICATES.includes(predicate.metric)));
    },
    invalidPredicate() {
      if (!this.selectedCampaignsHaveMetrics) {
        return this.value.result?.type?.value === 'AUTOMATE_BID_OPTIMIZATION' || (this.value.predicates?.some((predicate) => METRICS_RELATED_PREDICATES.includes(predicate.metric?.value)) ?? true);
      }

      return false;
    },
  },
  watch: {
    value: {
      handler(val) {
        this.$emit('updateForm', val);
      },
      deep: true,
    },
    initialValue: {
      handler(val) {
        const value = JSON.parse(JSON.stringify(val));
        if (value.cancelled) {
          value.campaignTargets.cancelled = true;
        }
        delete value.cancelled;

        value.campaignTargets.editId = value.editId;

        this.value = value;
      },
    },
    currentBidRuleTemplate: {
      handler(val) {
        if (val == null) {
          return;
        }
        const { name, ...template } = JSON.parse(JSON.stringify(apiBidRuleToForm(val)));

        this.value = {
          ...template,
          editId: Math.random(),
          allCampaignsSelected: this.value.allCampaignsSelected,
          campaignTargets: this.value.campaignTargets,
        };
      },
    },

  },
  methods: {
    filterResult(resultType) {
      if (this.selectedCampaignsHaveMetrics) return false;
      return resultType.value === 'AUTOMATE_BID_OPTIMIZATION';
    },
    addPredicate() {
      this.value.predicates.push({
        operand: { name: '<', value: 'LESS_THAN' },
        metric: { name: 'Impressions', value: 'IMPRESSIONS' },
        value: 0,
        id: new Date().getMilliseconds(),
      });
    },
    removePredicate(id) {
      this.value.predicates = this.value.predicates.filter((predicate) => predicate.id !== id);
    },
    hasWeekdays(recurrence) {
      return isDayOfWeekRecurrence(recurrence);
    },
  },
};
</script>

<style scoped>
    .if-label {
        flex-basis: 10%
    }
    .target-dropdown {
      flex: 2;
    }
    .full-dropdown {
      min-width: 25rem;
      max-width: 25rem;
    }
    .small-dropdown {
      width: 8rem;
    }
    .button-gap {
      gap: 1rem;
    }
    .predicate-gap {
      gap: 1rem;
    }
    .metric-dropdown {
      width: 12rem;
    }
</style>

<style>
  .p-multiselect-header .p-checkbox::before {
    content: "Select All ";
    margin-right: 0.5rem;
    display: inline-block;
    width: 65px;
    height: 15px;
    margin-right: 5px;
    margin-top: 2px;
  }
  .p-multiselect-header .p-checkbox {
    width: 90px !important;
  }

  .fixed-dropdown {
    width: 20rem;
  }
</style>
