<script setup>
import {
  defineProps, defineEmits, ref, computed,
} from 'vue';
import BidRuleCheckbox from '@/components/dashboard/bidrules/list/BidRuleCheckbox.vue';
import { a2caAuthorizedClient } from '@/api/a2caAuthorizedClient';
import { useToast } from 'primevue/usetoast';
import { METRICS_RELATED_PREDICATES } from '@/constants/bidRulesConstants';

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  campaigns: {
    type: Array,
    required: true,
  },
  bidRules: {
    type: Array,
    required: true,
  },
  templates: {
    type: Array,
    required: true,
  },
  selectedProfile: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(['update:modelValue']);
const { mutateAsync: createBidRulesInBatch } = a2caAuthorizedClient.useCreateBidRulesInBatchMutation();
const { mutateAsync: updateBidRuleAsync } = a2caAuthorizedClient.useUpdateBidRule();
const toast = useToast();
const showCampaignMultiselect = ref(false);
const allCampaignsSelected = ref(false);
const creatingRules = ref(false);
const campaignTargets = ref([]);
const allTemplatesSelected = ref(false);

const updateVisibility = (newValue) => {
  emit('update:modelValue', newValue);
};

const notAllSelectedCampaignsHaveMetrics = () => {
  if (allCampaignsSelected.value) {
    return !props.campaigns.every((c) => c.hasMetrics);
  }
  return !campaignTargets.value.every((c) => c.hasMetrics);
};

const allCampaignsHaveMetrics = computed(() => !props.campaigns.every((c) => c.hasMetrics));

const filteredTemplates = computed(() => {
  if (!notAllSelectedCampaignsHaveMetrics()) {
    return props.templates;
  }

  return props.templates
    .filter((template) => !template.result.type === 'AUTOMATE_BID_OPTIMIZATION')
    .filter((template) => !template.predicates.some((predicate) => METRICS_RELATED_PREDICATES.includes(predicate.metric)))
    .map((br) => br.name);
});

const { data: setupState } = a2caAuthorizedClient.useGetSetUpState(props.selectedProfile.profileId);

const selectedRules = ref({});
const showNoSelectedCampaignsWarning = computed(() => !allCampaignsSelected.value && campaignTargets.value.length === 0);
const canCreateRules = computed(() => !showNoSelectedCampaignsWarning.value && Object.keys(selectedRules.value).length > 0);

const onRuleClick = (template) => {
  if (selectedRules.value[template.name]) {
    allTemplatesSelected.value = false;
    delete selectedRules.value[template.name];

    selectedRules.value = { ...selectedRules.value };
    return;
  }

  selectedRules.value = {
    ...selectedRules.value,
    [template.name]: template,
  };
};

const bidRuleTemplateExists = (template) => props.bidRules.find((br) => br.name === template.name);

const getDisabledMessage = (template) => {
  if (!setupState.value.hasSetupMetrics) {
    return 'This rule cannot be added until metrics are assigned to your campaigns';
  }
  if (showNoSelectedCampaignsWarning.value) {
    return 'Please select campaigns first.';
  }
  if (bidRuleTemplateExists(template)) {
    return 'This quick bid rule has already been added.';
  }
  if (!filteredTemplates.value.some((ft) => ft.name === template.name)) {
    return 'This rule cannot be added until metrics are assigned to your campaigns.';
  }

  return 'This template cannot be selected';
};
const onCreateTemplate = async () => {
  creatingRules.value = true;
  try {
    const nonExistingRules = Object.values(selectedRules.value).filter((template) => !bidRuleTemplateExists(template));
    await createBidRulesInBatch([
      props.selectedProfile.profileId,
      props.selectedProfile.region,
      nonExistingRules,
      campaignTargets.value.map((campaign) => campaign.campaignId),
      allCampaignsSelected.value,
    ]);

    const existingRules = Object.values(selectedRules.value).filter(bidRuleTemplateExists);
    await Promise.all(existingRules.map((template) => {
      const bidRule = bidRuleTemplateExists(template);

      return updateBidRuleAsync([
        props.selectedProfile.profileId,
        props.selectedProfile.region,
        {
          ...template,
          campaignTargets: allCampaignsSelected.value
            ? []
            : [
              ...bidRule.campaignTargets,
              ...campaignTargets.value.map((campaign) => campaign.campaignId),
            ].filter(Boolean),
          allCampaignsSelected: allCampaignsSelected.value,
        },
        bidRule.id,
      ]);
    }));

    // Reset modal
    selectedRules.value = {};
    campaignTargets.value = [];
    allCampaignsSelected.value = false;
    allTemplatesSelected.value = false;

    toast.add({
      severity: 'success',
      summary: 'New bid rules created',
      detail: 'We created the new template rules.',
      life: 10000,
    });

    // Close modal
    emit('update:modelValue', false);
  } catch (error) {
    toast.add({
      severity: 'error',
      summary: 'Bid rule creation failed',
      detail: 'We could not create that rule for the assigned campaign.',
      life: 10000,
    });
  } finally {
    creatingRules.value = false;
  }
};
const bidRuleExistForCampaign = (template) => {
  const existingRule = bidRuleTemplateExists(template);

  if (existingRule) {
    // If exist and the selected campaign has it or the bidrule applies for all campaigns
    return existingRule.allCampaignsSelected
        || campaignTargets.value.map((campaign) => campaign.campaignId).every(
          (target) => existingRule.campaignTargets.includes(target),
        );
  }
  return false;
};

const disableTemplate = (template) => showNoSelectedCampaignsWarning.value || (bidRuleExistForCampaign(template) && !allCampaignsSelected.value) || !filteredTemplates.value.some((ft) => ft.name === template.name);

const onSelectAllTemplates = () => {
  if (allTemplatesSelected.value) {
    selectedRules.value = {
      ...filteredTemplates.value.reduce((acc, template) => {
        if (disableTemplate(template)) { return acc; }
        acc[template.name] = template;
        return acc;
      }, {}),
    };
  } else {
    selectedRules.value = {};
  }
};

const isRuleExtended = (template) => bidRuleTemplateExists(template)
      && !showNoSelectedCampaignsWarning.value
      && (!bidRuleExistForCampaign(template) || allCampaignsSelected.value);
</script>

<template>
  <Dialog
    :visible="modelValue"
    modal
    header="Quick Rule Setup"
    :style="{ width: '60vw' }"
    @update:visible="updateVisibility"
  >
    <section
      v-if="setupState.hasSetupMetrics"
      class="flex justify-content-between"
    >
      <div>
        <Button
          class="p-button-secondary"
          label="Select campaigns"
          icon="pi pi-external-link"
          @click="showCampaignMultiselect = true"
        />
        <InlineMessage
          v-if="showNoSelectedCampaignsWarning"
          class="ml-3"
          severity="warn"
        >
          Select the campaigns you want these bid rules to apply to.
        </InlineMessage>
      </div>
      <Button
        v-tooltip="{ value: 'You need to select a rule and assign campaign before.', disabled: canCreateRules }"
        class="p-button-primary"
        :disabled="!canCreateRules"
        label="Create bid rules"
        :loading="creatingRules"
        @click="onCreateTemplate"
      />
    </section>
    <InlineMessage
      v-else
      class="ml-3"
      severity="warn"
    >
      Please assign metrics to your campaigns if you want to add more quick rules. <router-link
        class="underline"
        :to="{name: 'metrics', query}"
      >
        Go to metrics
      </router-link>
    </InlineMessage>
    <InlineMessage
      v-if="!showNoSelectedCampaignsWarning && notAllSelectedCampaignsHaveMetrics()"
      class="mt-3"
      severity="warn"
    >
      Please assign metrics to your campaigns if you want to add more quick rules. <router-link
        class="underline"
        :to="{name: 'metrics', query}"
      >
        Go to metrics
      </router-link>
    </InlineMessage>
    <Dialog
      v-model:visible="showCampaignMultiselect"
      modal
      header="Quick Rule Setup: Campaign Selection"
      :style="{ width: '50vw' }"
      :closable="true"
    >
      <div class="">
        <p>Only campaigns with metrics assigned can be selected for the quick rule setup.</p> <span
          v-show="notAllSelectedCampaignsHaveMetrics()"
          v-tooltip="'Some campaigns do not have metrics assigned. Please update this in the Metrics section or remove the campaigns from the rule'"
          class="pi pi-exclamation-triangle text-orange-500"
        />
        <MultiSelect
          id="campaignTargets"
          v-model="campaignTargets"
          class="w-6"
          :options="campaigns"
          option-label="name"
          :option-disabled="campaign => !campaign.hasMetrics"

          placeholder="Select one or more campaigns"
          :filter="true"
          display="chip"
          :disabled="allCampaignsSelected"
          :show-toggle-all="false"
        />
      </div>
      <div class="flex">
        <div class="flex justify-content-end w-full">
          <div
            v-if="!allCampaignsHaveMetrics"
            class="flex align-items-center justify-content-end w-full"
          >
            <Checkbox
              v-model="allCampaignsSelected"
              input-id="selectAllCampaignsCheckbox"
              :binary="true"
            />
            <label
              for="selectAllCampaignsCheckbox"
              class="ml-2 mr-3"
            > Select all campaigns </label>
          </div>
          <Button
            size="small"
            :disabled="false"
            @click="showCampaignMultiselect = false"
          >
            OK
          </Button>
        </div>
      </div>
    </Dialog>
    <header class="flex justify-content-between mt-3">
      <h5>Select which bid rules to apply:</h5>
      <div v-if="!showNoSelectedCampaignsWarning">
        <Checkbox

          v-model="allTemplatesSelected"
          input-id="allTemplatesSelected"
          :binary="true"
          @change="onSelectAllTemplates"
        />
        <label
          for="allTemplatesSelected"
          class="ml-2 mr-3 font-bold"
        >Select all bid rules (recommended)</label>
      </div>
    </header>
    <section class="mt-1">
      <bid-rule-checkbox
        v-for="template in templates"
        :key="template.name"
        :template="template"
        :disabled="disableTemplate(template)"
        :disabled-message="getDisabledMessage(template)"
        :selected="!disableTemplate(template) && !!selectedRules[template.name]"
        :is-extend="isRuleExtended(template)"
        @click="onRuleClick"
      />
    </section>
  </Dialog>
</template>

<style scoped lang="scss">
section {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
}
</style>
