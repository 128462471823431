import { a2caAuthorizedClient } from '@/api/a2caAuthorizedClient';
import { useStore } from 'vuex';
import { METRICS_RELATED_PREDICATES } from '@/constants/bidRulesConstants';

// eslint-disable-next-line import/prefer-default-export
export const useBidRules = () => {
  const { getters } = useStore();
  const { selectedProfile } = getters;
  const { data: campaigns } = a2caAuthorizedClient.useGetCampaignsWithMetrics(
    selectedProfile.profileId,
    selectedProfile.region,
    'ENABLED',
  );

  const isBidRuleValid = (bidRule) => {
    const selectedCampaignsHaveMetrics = bidRule.allCampaignsSelected
      ? campaigns.value.every((c) => c.hasMetrics)
      : bidRule.campaignTargets?.every((c) => c.hasMetrics) ?? false;

    if (!selectedCampaignsHaveMetrics) {
      return bidRule.result?.type?.value !== 'AUTOMATE_BID_OPTIMIZATION' || bidRule.predicates?.some((predicate) => !METRICS_RELATED_PREDICATES.includes(predicate.metric?.value));
    }

    return true;
  };

  return { isBidRuleValid };
};
