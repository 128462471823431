<template>
  <Card>
    <template #content>
      <BidRuleForm
        :editable="editing"
        :disable-result-type="true"
        :initial-value="initialBidRule"
        :campaigns="campaigns"
        @updateForm="updateForm"
      />
    </template>
    <template #footer>
      <div class="flex">
        <div class="flex">
          <Button
            v-show="!editing"
            :loading="loading"
            class="p-button-danger"
            @click="deleteBidRule"
          >
            Delete
          </Button>
        </div>
        <div class="flex justify-content-end button-gap align-items-center flex-1">
          <p
            v-if="!invalidPredicate"
            class="m-0 font-italic text-gray-700"
          >
            {{ formattedExecutionDate }}
          </p>
          <Button
            v-show="!editing"
            :loading="loading"
            class="p-button-secondary"
            @click="startEdit"
          >
            Edit
          </Button>
          <Button
            v-show="editing"
            :loading="loading"
            class="p-button-secondary"
            @click="cancelEdit"
          >
            Cancel
          </Button>
          <Button
            v-show="editing"
            :loading="loading"
            :disabled="invalidPredicate"
            @click="updateBidRule"
          >
            Update
          </Button>
        </div>
      </div>
    </template>
  </Card>
  <Toast />
</template>
<script>
import { ref, computed, watch } from 'vue';
import dayjs from 'dayjs';
import { useToast } from 'primevue/usetoast';
import { METRICS_RELATED_PREDICATES } from '@/constants/bidRulesConstants';
import {
  BID_RULE_DELETION_SUCCESS, BID_RULE_UPDATE_SUCCESS,
} from '../../../../constants/toastConstants';
import { useValidateForm } from '../../../../utils/formUtils';
import BidRuleForm from '../form/BidRuleForm.vue';
import { a2caAuthorizedClient } from '../../../../api/a2caAuthorizedClient';
import { formBidRuleToApi } from '../../../../utils/bidRuleUtils';

export default {
  components: { BidRuleForm },
  inject: ['query'],
  props: {
    selectedProfile: {
      type: Object,
      required: true,
    },
    campaigns: {
      type: Array,
      required: true,
    },
    bidRule: {
      type: Object,
      required: true,
    },
    bidRuleId: {
      type: String,
      required: true,
    },
    nextTriggerDate: {
      type: String,
      required: false,
      default: null,
    },

  },
  emits: ['deleteBidRule'],
  setup(props, { emit }) {
    const initialBidRule = ref(props.bidRule);
    const currentBidRule = ref(JSON.parse(JSON.stringify(props.bidRule)));
    const executionDate = ref(props.nextTriggerDate);
    const lastEditedBidRule = ref(JSON.parse(JSON.stringify(props.bidRule)));
    const loading = ref(false);
    const editing = ref(false);
    const { mutateAsync: deleteBidRuleAsync } = a2caAuthorizedClient.useDeleteBidRule();
    const { mutateAsync: updateBidRuleAsync } = a2caAuthorizedClient.useUpdateBidRule();
    const { validate } = useValidateForm();
    const toast = useToast();

    watch(() => props.bidRule, (updatedBidRule) => {
      initialBidRule.value = updatedBidRule;
    });

    const startEdit = () => { editing.value = true; };
    const cancelEdit = () => {
      editing.value = false;
      initialBidRule.value = { ...JSON.parse(JSON.stringify(lastEditedBidRule.value)), editId: Math.random(), cancelled: true };
    };

    const updateForm = (updatedForm) => {
      currentBidRule.value = updatedForm;
    };
    const formattedExecutionDate = computed(() => {
      if (executionDate.value === null) {
        return '';
      }

      const formattedDate = dayjs(executionDate.value).format('dddd, D of MMMM');

      return `This rule will be executed next on ${formattedDate} at 5am UTC`;
    });

    const selectedCampaingHaveMetrics = (bidRule) => {
      if (bidRule.allCampaignsSelected) {
        return props.campaigns.every((c) => c.hasMetrics);
      }
      return bidRule.campaignTargets?.every((c) => c.hasMetrics) ?? false;
    };

    const invalidPredicate = computed(() => {
      if (currentBidRule.value.campaignTargets.length === 0 && !currentBidRule.value.allCampaignsSelected) return true;
      const hasMetrics = selectedCampaingHaveMetrics(currentBidRule.value);

      if (!hasMetrics) {
        return currentBidRule.value.result?.type.value === 'AUTOMATE_BID_OPTIMIZATION' || (currentBidRule.value.predicates?.some((predicate) => METRICS_RELATED_PREDICATES.includes(predicate.metric?.value)) ?? true);
      }

      return false;
    });

    const deleteBidRule = async () => {
      loading.value = true;

      await deleteBidRuleAsync([props.bidRuleId, props.selectedProfile.profileId]);
      toast.add({
        severity: 'success',
        summary: BID_RULE_DELETION_SUCCESS.summary,
        detail: BID_RULE_DELETION_SUCCESS.detail,
        life: 3000,
      });
      loading.value = false;
      emit('deleteBidRule', props.bidRuleId);
    };

    const updateBidRule = async () => {
      loading.value = true;
      if (!validate(currentBidRule.value)) {
        return;
      }
      const bidRule = formBidRuleToApi(currentBidRule.value);

      const updatedBidRule = await updateBidRuleAsync([
        props.selectedProfile.profileId,
        props.selectedProfile.region,
        bidRule,
        props.bidRuleId]);

      if (updatedBidRule?.type) {
        toast.add({
          severity: 'error',
          summary: 'Could not update bid rule',
          detail: 'Make sure all the campaigns in this rule have metrics assigned.',
          life: 3000,
        });
      } else {
        executionDate.value = updatedBidRule.nextTriggerDate;

        toast.add({
          severity: 'success',
          summary: BID_RULE_UPDATE_SUCCESS.summary,
          detail: BID_RULE_UPDATE_SUCCESS.detail,
          life: 3000,
        });
        lastEditedBidRule.value = JSON.parse(JSON.stringify(currentBidRule.value));
        editing.value = false;
      }

      loading.value = false;
    };

    return {
      initialBidRule,
      loading,
      editing,
      startEdit,
      cancelEdit,
      updateForm,
      formattedExecutionDate,
      invalidPredicate,
      deleteBidRule,
      updateBidRule,
    };
  },
};
</script>

<style scoped>
  .button-gap {
    gap: 1rem;
  }
</style>
-
